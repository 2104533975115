body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #e20613;
}

.action:hover {
  cursor: pointer;
  font-weight: bold;
  color: #e20613;
}

.simplebar-track {
  z-index: 1001;
}

.simplebar-scrollbar {
  z-index: 1001;
}

.simplebar-vertical.simplebar-track {
  width: 16px !important;
  background-color: #d6d6d6 !important;
  border-radius: 360px !important;
  background-clip: padding-box !important;
}

.simplebar-vertical .simplebar-scrollbar:before {
  width: 8px !important;
  left: 4px !important;
  top: 8px !important;
  bottom: 8px !important;
  opacity: 1 !important;
  background-color: #989797 !important;
  border-radius: 360px !important;
  background-clip: padding-box !important;
}

.simplebar-horizontal.simplebar-track {
  height: 16px !important;
  background-color: #d6d6d6 !important;
  border-radius: 360px !important;
  background-clip: padding-box !important;
}

.simplebar-horizontal .simplebar-scrollbar:before {
  height: 8px !important;
  top: 4px !important;
  left: 8px !important;
  right: 8px !important;
  opacity: 1 !important;
  background-color: #989797 !important;
  border-radius: 360px !important;
  background-clip: padding-box !important;
}
